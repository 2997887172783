<template>
  <layout v-load="loading">
    <template #header>
      <h1 v-if="employee" class="title-1">{{ employee.name }}</h1>
    </template>
    <div>
      <b-card class="tabs d-none d-md-block">
        <b-tabs
            content-class="tab-container p-3 text-muted"
            nav-class="nav-tabs-custom"
            v-model="tabIndex"
        >
          <b-tab>
            <template v-slot:title>
              <span class="font-size-18 font-weight-bold">Details</span>
            </template>
            <DetailsTab
                v-if="tabIndex === 0 && employee"
                :employee="employee"
            />
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="font-size-18 font-weight-bold">Contracts</span>
            </template>
            <ContractsTab
                v-if="tabIndex === 1 && employee"
                :employeeId="employee.uid"
                :contracts="employee.contracts"
                :documents="employee.document_links"
            />
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="font-size-18 font-weight-bold">Notes</span>
            </template>
            <NotesTab v-if="tabIndex === 2 && employee" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </layout>
</template>

<script>
import DetailsTab from './tabs/details';
import NotesTab from './tabs/notes';
import ContractsTab from './tabs/contracts';

export default {
  name: 'Employee',
  components: {
    NotesTab,
    DetailsTab,
    ContractsTab
  },
  data() {
    return {
      tabIndex: 0,
      employeeId: null,
      localLoading: false
    }
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.employeeId = id;
      this.localLoading = true;
      await this.$store.dispatch('employees/clearEmployee');
      await this.$store.dispatch('employees/getEmployeeById', id);
      this.localLoading = false;
    }
  },
  beforeDestroy() {
    this.$store.dispatch('employees/clearEmployee');
  },
  computed: {
    employee() {
      return this.$store.getters["employees/employee"];
    },
    loading() {
      return this.$store.state.employees.loading || this.localLoading;
    }
  },
  created() {
    this.tabIndex = +this.$route.query.tab || 0
  },
  watch: {
    tabIndex() {
      this.$router.replace({ query: {tab: this.tabIndex} }).catch(e => e);
    }
  },
}
</script>

<style>
.tabs-block {
  background-color: #FFFFFF;
  border-radius: 10px;
}
</style>
